<template>
  <div id="REBATE_BADGE_GROUP_MODAL" style="height: 100%, width: 100%">
    <b-modal v-model="modalShow" size="lg" footer-class="footerClass" title="Edit Assigned Rebate"
      no-close-on-backdrop  content-class="shadow"
      @hide="modalCancel"
      scrollable
    >
      <b-form-group>
        <label class="mr-1">{{ $t(`project.field.rebates`) }}</label>
        <button class="btn-action" @click="rebateSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/></button>
      
        <BadgeGroup v-model="rebates">
          <template v-slot:default="{ item, index }">
            <Badge @badgeRemove="rebateBadgeRemove(index)"
              :text="`${item.name} ${formatRebate(item.rebate)}`" 
              variant="primary"
              :pillable="!!item.pillable" :key="index" />
            </template>
        </BadgeGroup>
      </b-form-group>

      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="ok">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <RebateSelectorModal v-if="rebateSelectorShow" :show.sync="rebateSelectorShow" @cancel="rebateSelectorCancel" @ok="rebateSelectorOk"/>
    
  </div>
</template>

<script>
import { objectClone } from '@/helpers'
import { toFixed } from '@/helpers/task-duration-process'

export default {
  name: 'RebateBadgeGroupModal',
  components: {
    BadgeGroup: () => import('@/components/BadgeGroup/BadgeGroup'),
    Badge: () => import('@/components/BadgeGroup/components/Badge'),
    RebateSelectorModal: () => import('@/components/modal/RebateSelectorModal'),
    
  },
  props: {
    show: { type: Boolean, required: true }
    , rebateList: { type: Array, default: () => [] }
  },
  data() {
    return {
      modalShow: false
      , rebateSelectorShow: false
      , rebates: []
    }
  },
  created() {
    this.modifiedList = []
  },
  beforeMount() {
    this.rebates.splice(0, this.rebates.length, ...this.rebateList)
    this.modalShow = true
  },
  mounted() {

  },
  beforeDestroy() {
    this.modifiedList = null
  },
  watch: {
    show(newValue) {
      if (newValue != this.modalShow) {
        this.modifiedList.splice(0, this.modifiedList.length)
        this.modalShow = newValue
        if (newValue) {
          this.rebates.splice(0, this.rebates.length, ...this.rebateList)
        }
      }
    }
  },
  methods: {
    modalCancel() {
      //listen to hide instead of hidden event, this.modifiedList has not been reset yet in hide event
      this.$emit('cancel', { modifiedList: objectClone(this.modifiedList) })
    }
    , rebateSelectorCancel({ modifiedList=[] }={}) {
      if (modifiedList.length > 0) {
        this.modifiedList.push(...modifiedList)
      }
      this.rebateSelectorShow = false
    }
    , rebateBadgeRemove: function(index) {
      this.rebates.splice(index,1)
    }
    , rebateSelectorToggle() {
      this.rebateSelectorShow = true
    }
    , rebateSelectorOk({ details }) {
      this.rebates.push( ...details.map(i => { return { uuId: i.uuId, name: i.name, rebate: i.rebate }}) )
    }
    , ok() {
      this.$emit('ok', objectClone(this.rebates))
      this.$emit('update:show', false)
    }
    , formatRebate(val) {
      if (isNaN(val)) {
        return ''
      }
      return ` (${toFixed(val * 100, 0)}%)`
    }
  }
}
</script>